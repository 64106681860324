import React, { ReactElement, useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import './WhatsNew.scss'
import moment from 'moment'
import { orderBy } from 'lodash'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import loadable from '@loadable/component'
import placeholder from '../../assets/images/location-images/location-placeholder.png'
import sortIcon from '../../assets/images/mobile-images/icons/sort-icon.png'
import Seo from '../../components/Seo/Seo'
import { PropertyDataTypes } from '../Amenities/Amenities'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const SearchSelect = loadable(() =>
  import('../../components/SearchSelect/SearchSelect'),
)
const options = [
  { value: 'All', label: 'All' },
  { value: 'Latest', label: 'Latest' },
  { value: 'Last Week', label: 'Last Week' },
  { value: 'This Month', label: 'This Month' },
  { value: 'Last Month', label: 'Last Month' },
]

type ContentTypes = {
  category: string
  title: string
  tags?: string[]
  shortDescription: string
  subtitle: string
  image: {
    url: string
    description: string
    featured: string
  }
  videoLink: string
  subcategory: number
  id: string
  slug: string
  contents: {
    video: string
    description: string
    title: string
  }[]
  updatedAt: string
  featured: string
  datePublished: string
}

type PageContextType = {
  title: string
}

type WhatsNewPropTypes = {
  data: {
    allContent: { nodes: ContentTypes[] }
    allProperty: { nodes: PropertyDataTypes[] }
    footerLinks: { items: any }
  }
  pageContext: PageContextType
  location: {
    state: {
      type?: any
    }
    pathname: string
  }
}

const WhatsNew = ({
  data: {
    allContent: { nodes: contentList },
    allProperty: { nodes: propertyList },
    footerLinks: { items: footerData },
  },
  pageContext,
  location,
}: WhatsNewPropTypes): ReactElement => {
  const blogState = { type: 'blogs' }
  const vlogState = { type: 'vlogs' }
  const newsState = { type: 'news' }
  const eventsState = { type: 'events' }
  const [sortValue, setSortValue] = useState('All')
  const [pageCount, setPageCount] = useState(1)
  const [selectedTab, setSelectedTab] = useState('')
  const [items, setItems] = useState<ContentTypes[]>([])
  const [recentPost, setRecentPost] = useState<ContentTypes[]>([])
  const [selectedContent, setSelectedContent] = useState<ContentTypes[]>(
    contentList.filter((content) => content.category === selectedTab),
  )
  const [establishments] = useState(
    propertyList.filter((val) =>
      val.towers[0]?.locationLandmarks?.landmarksEstablishment?.items?.map(
        (landmark) => landmark?.establishmentName,
      ),
    ),
  )
  const [page, setPage] = useState(1)

  const [sortedContent, setSortedContent] = useState<ContentTypes[]>(
    contentList.filter((content) => content.category === selectedTab),
  )

  useEffect(() => {
    const filterContent = contentList.filter(
      (content) => content.category === selectedTab,
    )

    const recentContent = filterContent.sort(
      (a, b) =>
        new Date(b.datePublished).getTime() -
        new Date(a.datePublished).getTime(),
    )
    setRecentPost(recentContent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab])

  useEffect(() => {
    setPageCount(
      Math.ceil((sortedContent.length >= 12 ? sortedContent.length : 12) / 12),
    )
    setItems(sortedContent.slice(12 * (page - 1), 12 * page))

    // eslint-disable-next-line no-unused-expressions
    document.getElementById('content')?.scrollIntoView()
  }, [sortedContent, page])

  const getDefaultType = (): string => {
    const url = window.location.toString()

    if (url.includes('blog')) {
      return 'blogs'
    }

    if (url.includes('vlogs')) {
      return 'vlogs'
    }

    return 'news'
  }

  useEffect(() => {
    const selected = contentList.filter(
      (content) => content.category === selectedTab,
    )
    const withDates = orderBy(
      selected?.filter((content) => content.datePublished),
      [(data): any => new Date(data.datePublished)],
      ['desc'],
    )
    const noDates = selected?.filter((content) => content.datePublished === '')
    const result = [...withDates, ...noDates]

    setSelectedContent(result)
    setSortedContent(result)
    setPage(1)
  }, [contentList, propertyList, selectedTab])

  useEffect(() => {
    setSelectedTab(
      location?.state?.type ? location?.state?.type : getDefaultType(),
    )
  }, [location])

  const [sort, setSort] = useState(false)

  const handleShow = (): void => {
    setSort(!sort)
  }

  const brokenImage = (event: any): void => {
    event.target.src = placeholder // eslint-disable-line no-param-reassign
  }

  useEffect(() => {
    const lasWeek = moment().subtract(14, 'days').format('MM/DD/YY')
    const thisMonth = moment().subtract(30, 'days').format('MM/DD/YY')
    const lastMonth = moment().subtract(62, 'days').format('MM/DD/YY')
    const today = moment().format('MM/DD/YY')

    const moments = selectedContent.map((d) => moment(d.datePublished))
    const maxDate = moment.max(moments)

    const latestArticles = selectedContent.filter(
      (article) => article.datePublished === maxDate.format('MM/DD/YYYY'),
    )
    const lastWeekArticles = selectedContent.filter((article) =>
      moment(article.datePublished).isBetween(lasWeek, today),
    )
    const thisMonthArticles = selectedContent.filter((article) =>
      moment(article.datePublished).isBetween(thisMonth, today),
    )
    const lastMonthArticles = selectedContent.filter((article) =>
      moment(article.datePublished).isBetween(lastMonth, today),
    )

    if (sortValue === 'All') {
      setSortedContent(selectedContent)
    } else if (sortValue === 'Latest') {
      setSortedContent(latestArticles)
    } else if (sortValue === 'Last Week') {
      setSortedContent(lastWeekArticles)
    } else if (sortValue === 'This Month') {
      setSortedContent(thisMonthArticles)
    } else if (sortValue === 'Last Month') {
      setSortedContent(lastMonthArticles)
    }
  }, [sortValue, selectedContent, selectedTab])

  return (
    <Layout footerData={footerData}>
      <Seo
        title={`${pageContext?.title} | Amaia Land | Affordable House and Lot & Condos in the Philippines`}
        ogMetaData={{
          description:
            'Learn the latest news, events and happenings while learning practical tips straight from our blog and vlogs! Enjoy reading and watching!',
        }}
      />

      <div className="whats-new">
        <h1 className="whats-new-label">
          WHAT&apos;S <span className="whats-new-green">NEW</span>
        </h1>
        <div className="catalog-nav" id="catalog-nav">
          <Link to="/news-and-events/" state={newsState}>
            <button
              type="button"
              className={selectedTab === 'news' ? 'selected item' : 'item'}
              onClick={(): void => setSelectedTab('news')}
            >
              News
              <div className="greenLine" />
            </button>
          </Link>
          <Link to="/news-and-events/" state={eventsState}>
            <button
              type="button"
              className={selectedTab === 'events' ? 'selected item' : 'item'}
              onClick={(): void => setSelectedTab('events')}
            >
              Events
              <div className="greenLine" />
            </button>
          </Link>
          <Link to="/blog/" state={blogState}>
            <button
              type="button"
              className={selectedTab === 'blogs' ? 'selected item' : 'item'}
              onClick={(): void => setSelectedTab('blogs')}
            >
              Blogs
              <div className="greenLine" />
            </button>
          </Link>
          <Link to="/vlogs/" state={vlogState}>
            <button
              type="button"
              className={selectedTab === 'vlogs' ? 'selected item' : 'item'}
              onClick={(): void => setSelectedTab('vlogs')}
            >
              Vlogs
              <div className="greenLine" />
            </button>
          </Link>
          <SearchSelect
            className="whatsNew"
            setSortValue={setSortValue}
            options={options}
            placeholder="SORT BY"
          />
        </div>
        <div className="content-container" id="content">
          <div
            className="content-container-main"
            style={{ background: '#fff', padding: '20px 10px' }}
          >
            <div className="whats-new-catalog-content">
              <div className="whats-new-featured">
                {selectedContent.map((content) => {
                  return (
                    <Link
                      key={content.slug}
                      to={`${location.pathname}${
                        content?.tags ? content?.tags[0] : ''
                      }/`}
                    >
                      {content.featured ? (
                        <div>
                          <img
                            src={content?.image?.url}
                            onError={brokenImage}
                            alt="featuredImage"
                          />
                          <div className="info">
                            <div className="info-details">
                              <span className="type">
                                {content.category.toLocaleUpperCase()}
                              </span>
                              <span className="featured-tag">FEATURED</span>
                              <h1 className="title">{content?.title}</h1>
                              <span className="date">
                                {content?.datePublished}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </Link>
                  )
                })}
              </div>
              <div className="catalog-item-container">
                {items.slice(0, 4).map((content) => {
                  return (
                    <Link
                      key={content.slug}
                      to={`${location.pathname}${
                        content?.tags ? content?.tags[0] : ''
                      }/`}
                      className="link"
                    >
                      <div key={content.slug} className="item">
                        <div className="image-container">
                          <img
                            src={content?.image?.url}
                            onError={brokenImage}
                            alt="articleImage"
                          />
                        </div>
                        <div className="info">
                          <div className="info-details">
                            <div className="status-container-mobile">
                              <span>
                                {content.category.toLocaleUpperCase()}
                              </span>
                            </div>
                            <h1 className="title">
                              {content?.title?.substring(0, 40) +
                                (content?.title?.length > 40 ? '...' : '')}
                            </h1>
                            <span className="date">
                              {content?.datePublished}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>
            <div className="whats-new-catalog-extended">
              {items.length >= 4 ? (
                <div className="catalog-item-container">
                  {items.slice(4).map((content) => {
                    return (
                      <Link
                        key={content.slug}
                        to={`${location.pathname}${
                          content?.tags ? content?.tags[0] : ''
                        }/`}
                        className="link"
                      >
                        <div key={content.slug} className="item">
                          <div className="image-container">
                            <img
                              src={content?.image?.url}
                              onError={brokenImage}
                              alt="articleImage"
                            />
                          </div>
                          <div className="info">
                            <div className="info-details">
                              <div className="status-container-mobile">
                                <span>
                                  {content.category.toLocaleUpperCase()}
                                </span>
                              </div>
                              <h1 className="title">
                                {content?.title?.substring(0, 40) +
                                  (content?.title?.length > 40 ? '...' : '')}
                              </h1>
                              <span className="date">
                                {content?.datePublished}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    )
                  })}
                </div>
              ) : null}
            </div>
            <div className="pagination">
              <LeftOutlined
                rev=""
                onClick={(): void => {
                  const nums = Array.from(Array(pageCount).keys()).slice(-1)[0]
                  if (page === 1) {
                    setPage(nums + 1)
                  } else {
                    setPage(page - 1)
                  }
                }}
              />
              <div className="pagination-nums">
                {Array.from(Array(pageCount).keys()).map((el) => {
                  return (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                    <span
                      onClick={(): void => {
                        setPage(el + 1)
                      }}
                      key={el}
                      className={
                        page === el + 1 ? 'pagination-nums-selected' : ''
                      }
                    >
                      {el + 1}
                    </span>
                  )
                })}
              </div>
              <RightOutlined
                rev=""
                onClick={(): void => {
                  const nums =
                    Array.from(Array(pageCount).keys()).slice(-1)[0] + 1

                  if (page < nums) {
                    setPage(page + 1)
                  } else {
                    setPage(1)
                  }
                }}
              />
            </div>
          </div>
          <div className="recent-post">
            <h3>Recent Post</h3>
            <div className="recent-post-links">
              {recentPost.slice(0, 10).map((content) => {
                return (
                  <Link
                    key={content.slug}
                    to={`${location.pathname}${
                      content?.tags ? content?.tags[0] : ''
                    }/`}
                  >
                    {content.title}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
        <div className="inquire-now-link">
          <div>
            <h3>LOOKING FOR YOUR DREAM HOME?</h3>
            <Link to="/contact-us/">INQUIRE NOW</Link>
          </div>
        </div>
        <div className="whats-new-content">
          <div className="catalog">
            <div className="catalog-nav-mobile">
              <Link to="/news-and-events/" state={newsState}>
                <button
                  type="button"
                  className={selectedTab === 'news' ? 'selected item' : 'item'}
                  onClick={(): void => setSelectedTab('news')}
                >
                  News
                  <div className="greenLine" />
                </button>
              </Link>
              <Link to="/news-and-events/" state={eventsState}>
                <button
                  type="button"
                  className={
                    selectedTab === 'events' ? 'selected item' : 'item'
                  }
                  onClick={(): void => setSelectedTab('events')}
                >
                  Events
                  <div className="greenLine" />
                </button>
              </Link>
              <Link to="/blog/" state={blogState}>
                <button
                  type="button"
                  className={selectedTab === 'blogs' ? 'selected item' : 'item'}
                  onClick={(): void => setSelectedTab('blogs')}
                >
                  Blogs
                  <div className="greenLine" />
                </button>
              </Link>
              <Link to="/vlogs/" state={vlogState}>
                <button
                  type="button"
                  className={selectedTab === 'vlogs' ? 'selected item' : 'item'}
                  onClick={(): void => setSelectedTab('vlogs')}
                >
                  Vlogs
                  <div className="greenLine" />
                </button>
              </Link>
              <SearchSelect
                className="whatsNew"
                setSortValue={setSortValue}
                options={options}
                placeholder="SORT BY"
              />
            </div>
            <div className="catalog-mobile">
              <p>
                {selectedContent.length} Posted {selectedTab}
              </p>
              <div className="sort-section">
                {sort ? (
                  <SearchSelect
                    className="whatsNew-mobile"
                    setSortValue={setSortValue}
                    options={options}
                    placeholder="SORT BY"
                  />
                ) : null}
                <button type="button" onClick={handleShow}>
                  <img src={sortIcon} alt="sort-icon" />
                </button>
              </div>
            </div>
            <div className="catalog-extended-mobile">
              <div className="catalog-item-container">
                {sortedContent.map((content) => {
                  return (
                    <Link
                      key={content.slug}
                      to={`${location.pathname}${
                        content?.tags ? content?.tags[0] : ''
                      }/`}
                      className="link"
                    >
                      <div key={content.slug} className="item">
                        <div className="image-container">
                          <img
                            src={content?.image?.url}
                            onError={brokenImage}
                            alt="articleImage"
                          />
                        </div>
                        <div className="info">
                          <div className="info-details">
                            <div className="status-container-mobile">
                              <span>
                                {content.category.toLocaleUpperCase()}
                              </span>
                            </div>
                            <h1 className="title">
                              {content?.title?.substring(0, 40) +
                                (content?.title?.length > 40 ? '...' : '')}
                            </h1>
                            <span className="date">
                              {content?.datePublished}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>
            <div className="inquire-now-link-mobile">
              <div>
                <h3>LOOKING FOR YOUR DREAM HOME?</h3>
                <Link to="/contact-us/">INQUIRE NOW</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WhatsNew

export const pageQuery = graphql`
  query {
    allContent {
      nodes {
        ...SustainabilityFields
      }
    }
    allProperty {
      nodes {
        ...PropertyPageFields
      }
    }
    footerLinks {
      items
    }
  }
`
